import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteTwo from "./ProtectedRouteTwo";
import {
  authRoutes,
  commonRoutes,
  loginRoutes,
  routeWithoutLayout,
} from "./allRoutes";

const Router = () => {
  const userData = useSelector((store) => store.tenantAuth.userData);
  const isOfferLinkActive = useSelector(
    (store) => store.property.isOfferAcceptedNavigationActive
  );
  const FallbackLoadingComponent = () => {
    return (
      <div>
        <div className="text-center p-3">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<FallbackLoadingComponent />}>
        <Routes>
          {" "}
          {routeWithoutLayout.map((el, index) => (
            <Route path={el.path} key={el.path} element={<el.component />} />
          ))}
          {authRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={
                <ProtectedRoute role={userData?.role}>
                  <item.component />
                </ProtectedRoute>
              }
            />
          ))}
          {loginRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={
                <ProtectedRouteTwo
                  path={item.path}
                  role={userData?.role}
                  isOfferLinkActive={isOfferLinkActive}
                >
                  <item.component />
                </ProtectedRouteTwo>
              }
            />
          ))}
          {commonRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={<item.component />}
            />
          ))}
        </Routes>
      </Suspense>
      <Toaster
        position="top-center"
        gutter={22}
        toastOptions={{
          success: {
            duration: 3000,
          },
          error: {
            duration: 5000,
          },
        }}
      />
    </>
  );
};

export default Router;
