export const apiEndPoints = {
  //user-end points
  USER: {
    login: "/user/login",
    register: "/user/signup",
    verifyUser: "/user/verify-jwt",
    forgetPassword: "/user/forgot-password",
    profileComplete: "/tenant/complete-profile",
    changePassword: "/user/update-password",
    updateCred: "/user/update-user",
    updatePassword: "/user/change-password",
    deleteUserApiEndPoint: "/user/delete",
    uploadProfilePictureApi: "/user/upload-profile",
    createHouseHold: "/household/create-house-hold",
    getHouseHold: "/household/list",
    deleteHouseHold: "/household/:id",
    editHouseHold: "/household/edit/:id",
    resend: "/household/resend-invite",
    checkUserExists: "/user/user-name/:email",
    checkPendingInvite: "/household/pending-requests",
    acceptHouseHoldInvitation: "/household/accept-invite",
    declineHouseHoldInvitation: "/household/reject-invite",
    offerTemplate: "/offer-template/update",
    getTemplate: "/offer-template/",
    offerTemplateReset: "/offer-template/reset",
    getAd: "/advertisement/details",
  },
  HouseHold: {
    emailInvite: "/household/accept-email-invite",
  },
  property: {
    getProperty: "/property/:id",
    getOfferStatusCheck: "/property-offer/user/:id",
    postOffer: "/property-offer/create",
    postFeedback: "/feedback/create",
  },
  LEADS: {
    leadDetails: "/leads/details/:leadId",
    noAuthLeadDetails: "/leads/no-auth/details/:leadId",
    edit: "/leads/no-auth/edit/:leadId",
    create: "/leads/create",
    // areaOfInterest: "/location/list",
    areaOfInterest: "/location/all-loc",
    interestSearch: "/location/list",

    notInterested: "/leads/not-interested/:id",
  },
  AGENT_OFFER_SETTINGS: {
    details: "/agent-settings/public/:branchId",
  },
  VIEWING: {
    cancel: "/viewing/cancel/:id",
  },
};
